
import ApiService from "@/core/services/ApiService";
import { defineComponent, ref } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useBus } from "../../../../bus";
import { Field } from "vee-validate";
import { DrawerComponent } from "@/assets/ts/components/_DrawerComponent";
import { apiEndpoint } from "@/mixin/apiEndpoint";
import { VueCookieNext } from 'vue-cookie-next';

export default defineComponent({
  mixins: [apiEndpoint],
  name: "kt-drawer-maptrainerInstitute-course-institute",
  components: { Field },

  data() {
    return {
      institutes: [],
      tranche: [],
      tranche_id: "",
      load: false,
      course_id: "",
      trainer_id: "",
      institute_id: "",
      entity_id: "",
      association: [],
      courseInfoData: false,
      courseList: [],
      loading: false,
    };
  },
  async created() {
    await this.getTranche();
    await this.associationList();
    // await this.trainingInstitute();
    this.emitter.on("entity_add_map_assessor_course_data", async (id) => {
      this.trainer_id = id;
      // api
      console.log(this.trainer_id);
    });
  },
  methods: {
    async trainingInstitute() {
      let entity_id = this.entity_id;
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      }
      let institute_info_id = "";
      if (VueCookieNext.getCookie('_seip_entity_type') == 1011) {
        institute_info_id = VueCookieNext.getCookie('_institute_info_id');
      }
      await ApiService.get(
        'institute/list?entity_id=' +
          entity_id +
          '&institute_info_id=' +
          institute_info_id
      )
        .then((response) => {
          this.institutes = response.data.data;
          console.log("ok" + response);
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getTranche() {
      await ApiService.get("configurations/tranche/list")
        .then((response) => {
          this.tranche = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async courseInfo() {
      await ApiService.get(
        "course/list?entity_id=" +
          this.entity_id +
          "&tranche=" +
          this.tranche_id +
          "&institute_info_id=" +
          this.institute_id
      )
        .then((response) => {
          console.log(response);
          this.courseList = response.data.data;
          console.log("course list" + JSON.stringify(this.courseList));
          this.courseInfoData = true;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async associationList() {
      let entity_id = '';
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      }
      await ApiService.get('entity/list?entity_id=' + entity_id)
        .then((response) => {
          this.association = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },

    async formSubmit() {
      let data = {
        entity_trainer_id: this.trainer_id,
        entity_info_id: this.entity_id,
        tranche_id: this.tranche_id,
        institute_info_id: this.institute_id,
        course_info_id: this.course_id,
      };

      this.loading = true;
      await ApiService.post(this.VUE_APP_COURSE_MAP_TRAINER_API, data)
        .then((response) => {
          DrawerComponent?.hideAll();
          this.loading = false;
          if (response.status == 200) {
            this.emitter.emit("courseMap-updated", true);
            // if(response.status="error")
            Swal.fire({
              title: "Success!",
              text: response.data.data,
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Ok",
              customClass: {
                confirmButton: "btn btn-success",
              },
            }).then(() => {});
          } else {
            let err = "";
            for (const field of Object.keys(response.data.errors)) {
              err += response.data.errors[field][0] + "<br>";
            }
            Swal.fire({
              title: "Please check all the required field",
              html: err,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Close",
              customClass: {
                confirmButton: "btn btn-danger",
              },
            });
          }
        })
        .catch(({ response }) => {
          this.loading = false;
          Swal.fire({
            title: "Unknown error",
            html: response.data.error,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Close",
            customClass: {
              confirmButton: "btn btn-danger",
            },
          });
          console.log(response);
        });
    },
  },
});
